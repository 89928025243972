import { Avatar, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, shorthands, tokens, typographyStyles } from "@fluentui/react-components";
import createProjectIcon from "../assets/icons/create_project.svg";
import InfoIcon from "../assets/icons/info.svg";
import logoutIcon from "../assets/icons/logout.svg";
import roundedPlusIcon from "../assets/icons/rounded_plus.svg";
import RupeeIcon from "../assets/icons/rupee.svg";

import ProjectIcon from "../assets/icons/project_search.svg";
import searchIcon from "../assets/icons/search.svg";
import WorkflowIcon from "../assets/icons/workflow_search.svg";
import ideaforgeLogoSmall from "../assets/logo/ideaforge_small.svg";

import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { AuthService, SearchResultsResponse, SearchService } from "../services/openapi";
import OrganisationList from "./OrganisationList";
import { sourceTypes, TourContext } from "./TourContext";
import DemoOrg from "./common/DemoOrg";
import { useOrganisationCheck } from "./hooks/useOrganisationCheck";
import { deafultWorkFlowData } from "./workflow_library/common/DefaultWorkFlowData";

export const TopBar = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { me, setMe, setIsNewProjectOpen } = useContext(AuthContext);
    const { setTourSource } = useContext(TourContext);
    const { isOrgActive, isOrgDemo } = useOrganisationCheck();
    const [orgDemoState, setOrgDemoState] = useState(false);


    const logout = () => {
        setMe(null)
        AuthService.logout()
            .catch((err) => console.error(err))
        navigate("/")
    };

    const startOnboarding = () => {
        setTourSource(sourceTypes.startOnboarding);
        navigate("/projects")
    }

    const handleTransactionNavigate = () => {
        if (isOrgDemo) {
            setOrgDemoState(true)
        } else {
            navigate("/transactions");
        }
    }


    const profileItems = [
        {
            name: "Transaction History",
            icon: RupeeIcon,
            onClick: handleTransactionNavigate,
        },
        {
            name: "Start Onboarding Tour",
            icon: InfoIcon,
            onClick: startOnboarding,
        },
        {
            name: "Logout",
            icon: logoutIcon,
            onClick: logout,
        },
    ]

    const toggleOrgDemoModal = (flag: boolean) => {
        setOrgDemoState(flag);
    };

    return <div className={classes.container}>
        <div className={classes.branding} onClick={() => { navigate(`/`); }}>
            <img src={ideaforgeLogoSmall} alt="ideaForge Logo" style={{ height: "38%", width: "100%" }} />
            <p>FLYGHT<span style={{ fontWeight: "700" }}>CLOUD</span></p>
        </div>
        <div style={{ display: "flex", justifyContent: "center", width: "35%" }}>
            <Search />
        </div>


        <div className={classes.actionsContainer}>
            <div className={classes.dropDownContainer} style={{ minWidth: "300px" }} id="org-switch-button">
                <OrganisationList />
            </div>
            <div className={classes.dropDownContainer} style={{
                opacity: isOrgActive ? 1 : 0.5,
                pointerEvents: isOrgActive ? "auto" : "none"
            }}
                id="new-button"
            >
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <div className={classes.new}>
                            <img src={roundedPlusIcon} alt="ideaForge Logo" />
                            <p>New</p>
                        </div>
                    </MenuTrigger>

                    <MenuPopover style={{ left: "15px" }}>
                        <MenuList style={{ width: "180px", left: "-15px" }}>
                            <MenuItem style={{ padding: 0 }} onClick={() => { setIsNewProjectOpen(true) }}>
                                <div className={classes.menulist} >
                                    <img src={createProjectIcon} alt='createProjectIcon' />
                                    <p>
                                        Create new project
                                    </p>
                                </div>
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Avatar
                        name={me?.email}
                        style={{ cursor: "pointer" }}
                    />
                </MenuTrigger>

                <MenuPopover>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "0.5rem", padding: "10px" }}>
                        <Avatar
                            name={me?.email} />
                        <p style={{ wordBreak: "break-word" }}>
                            {me?.email}
                        </p>
                    </div>
                    <MenuList style={{ width: "240px" }}>
                        {profileItems.map((item) => (
                            <MenuItem style={{ padding: "8px" }} key={item.name}>
                                <div className={classes.menulist} onClick={item.onClick}>
                                    <img src={item.icon} alt={item.name} />
                                    <p>{item.name}</p>
                                </div>
                            </MenuItem>
                        ))}
                    </MenuList>

                </MenuPopover>
            </Menu>
        </div>
        {
            orgDemoState && <DemoOrg
                visible={orgDemoState}
                toggleModal={toggleOrgDemoModal}
            />
        }
    </div >
}

const Search = () => {
    const classes = useSearchStyles()
    const { orgId } = useContext(AuthContext);

    const [searchValue, setSearchValue] = useState("")
    const [placeholder, setPlaceholder] = useState<string>("Search")
    const [results, setResults] = useState<{ projects: SearchResultsResponse["projects"], workflowTemplates: typeof deafultWorkFlowData }>()
    const [currentMenu, setCurrentMenu] = useState<"all" | "workflows" | "projects">("all")
    const [menuOpen, setMenuOpen] = useState(false)
    const { isOrgActive } = useOrganisationCheck();
    const resultsRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (searchValue.length >= 2) {
            const timer = setTimeout(() => {
                if (searchValue !== '') {
                    SearchService.search(searchValue, orgId?.orgId)
                        .then((r) => {
                            r.workflowTemplates = deafultWorkFlowData.filter(dw => dw.title.toLowerCase().includes(searchValue.toLowerCase()))
                            setResults(r);
                            setMenuOpen(true)
                        })
                        .catch((err) => console.error('Error while getting search results:', err));
                }
            }, 300); // Debounce
            return () => clearTimeout(timer);
        } else {
            setMenuOpen(false)
        }
    }, [searchValue]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (resultsRef.current && !resultsRef.current.contains(event.target)) {
                setMenuOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [resultsRef]);


    const handlePlaceholder = (focus: boolean) => () => {
        setPlaceholder(focus ? "Type here" : "Search")
    }

    const handleCurrentMenu = (menu: "all" | "workflows" | "projects") => () => {
        setCurrentMenu(menu)
    }

    const reset = () => {
        setMenuOpen(false); setSearchValue(""); setCurrentMenu("all");
    }


    return <div className={classes.searchContainer} style={{
        opacity: isOrgActive ? 1 : 0.5,
        pointerEvents: isOrgActive ? "auto" : "none"
    }}>
        <div className={classes.searchIconContainer}>
            <img src={searchIcon} alt="search icon" className={classes.searchIcon} />
        </div>
        <input placeholder={placeholder} className={classes.searchInput} onFocus={handlePlaceholder(true)} onBlur={handlePlaceholder(false)} onChange={(e) => { setSearchValue(e.target.value) }} value={searchValue} />


        {menuOpen && searchValue && <div className={classes.results} ref={resultsRef}>
            <div className={classes.resultsTopBar}>
                <p onClick={handleCurrentMenu("all")} className={classes.resultsTopBarText} style={{ borderBottom: currentMenu === "all" ? "2px solid #5C74E6" : undefined }}>All <span style={{ background: currentMenu === "all" ? "#DAE1FF" : "#EFEFEF" }}>{(results?.projects.length ?? 0) + (results?.workflowTemplates.length ?? 0)}</span></p>
                <p onClick={handleCurrentMenu("workflows")} className={classes.resultsTopBarText} style={{ borderBottom: currentMenu === "workflows" ? "2px solid #5C74E6" : undefined }}>Workflows <span style={{ background: currentMenu === "workflows" ? "#DAE1FF" : "#EFEFEF" }}>{(results?.workflowTemplates.length ?? 0)}</span></p>
                <p onClick={handleCurrentMenu("projects")} className={classes.resultsTopBarText} style={{ borderBottom: currentMenu === "projects" ? "2px solid #5C74E6" : undefined }}>Projects <span style={{ background: currentMenu === "projects" ? "#DAE1FF" : "#EFEFEF" }}>{(results?.projects.length ?? 0)}</span></p>
            </div>

            <div className={classes.resultsItemContainer} >
                {results && <>
                    {(currentMenu === "all" || currentMenu === "projects") && results.projects.map(item => <div className={classes.resultsItem} onClick={reset}>
                        <img src={ProjectIcon} alt="workflow" />
                        <div>
                            <Link className={classes.resultsItemName} to={`/projects/${item.id}`} >{item.name}</Link>
                        </div>
                    </div>)}
                    {currentMenu === "projects" && results.projects.length === 0 && <p>No results found</p>}

                    {(currentMenu === "all" || currentMenu === "workflows") && results.workflowTemplates.map(item =>
                        <div className={classes.resultsItem}>
                            <img src={WorkflowIcon} alt="workflow" />
                            <Link to={`/workFlow-library/default-workFlowLibrary/${item.id}`} style={{ textDecoration: "none" }} onClick={reset}>
                                <p className={classes.resultsItemName}>{item.title}</p>
                                <p className={classes.resultsItemDescription}>{item.info}</p>
                            </Link>
                        </div>
                    )}
                    {currentMenu === "workflows" && results.workflowTemplates.length === 0 && <p>No results found</p>}
                </>}
            </div>
        </div>}
    </div>
}

const useSearchStyles = makeStyles({
    results: {
        position: "absolute",
        overflowY: "auto",
        zIndex: 10,
        top: "42px",
        left: "0",
        width: "100%",
        maxHeight: "450px",
        backgroundColor: "white",
        ...shorthands.borderRadius("8px"),
        boxShadow: "-1px 1px 20px 0px rgba(0, 0, 0, 0.20)",
        ...shorthands.border("1px", "solid", "#E3E9F2")
    },
    resultsTopBar: {
        display: "flex",
        ...shorthands.gap("1rem"),
        paddingLeft: "20px",
        paddingTop: "12px",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
    },
    resultsTopBarText: {
        cursor: "pointer",
        paddingBottom: "6px",
        color: "#000",
        fontSize: "14px",
        lineHeight: "20px",
        "> span": {
            display: "inline-flex",
            width: "12px",
            ...shorthands.padding("0px", "2px"),
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            ...shorthands.borderRadius("2px"),
            marginLeft: "4px",
        }
    },
    resultsItemContainer: {
        ...shorthands.padding("8px")
    },
    resultsItem: {
        display: "flex",
        ...shorthands.gap("8px"),
        alignItems: "center",
        cursor: "pointer"
    },
    resultsItemName: {
        color: "#000",
        fontSize: "14px",
        lineHeight: "20px",
        ...shorthands.textDecoration("none")
    },
    resultsItemDescription: {
        color: "#7D8FA9",
        fontSize: "12px",
        lineHeight: "16px"
    },
    searchContainer: {
        position: 'relative',
        width: '80%',
    },
    searchIconContainer: {
        position: 'absolute',
        height: '100%',
        left: "1rem",
        display: "flex",
        alignItems: "center",
    },
    searchIcon: {
        width: '20px',
        height: '20px',
    },
    searchInput: {
        ...shorthands.borderRadius("0.5rem"),
        ...shorthands.border("none"),
        ...shorthands.outline("none"),
        ...typographyStyles.body1,
        height: "2rem",
        width: "100%",
        paddingLeft: "2.5rem",
        paddingBottom: "2px",
        backgroundColor: "#5E5CE6",
        color: tokens.colorNeutralBackground1,
        "::placeholder": {
            color: tokens.colorNeutralBackground1
        },
        ":focus": {
            ...shorthands.borderColor("red"),
            boxShadow: "none",
            ...shorthands.outline("1px"),
            ...shorthands.border("2px"),
        }
    },
})


const useStyles = makeStyles({
    container: {
        width: '100%',
        backgroundColor: tokens.colorBrandForeground1,
        height: "3rem",
        minHeight: "3rem",
        paddingLeft: "1.25rem",
        paddingRight: "1.25rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    branding: {
        display: "flex",
        ...shorthands.gap('0.375rem'),
        color: tokens.colorNeutralBackground1,
        fontSize: "21px",
        lineHeight: "18px",
        height: "100%",
        alignItems: "center",
        cursor: "pointer"
    },


    new: {
        display: "flex",
        alignItems: "center",
        ...shorthands.gap('0.5rem'),
        color: tokens.colorNeutralBackground1,
        fontSize: "18px",
        lineHeight: "22px",
        cursor: "pointer",
    },
    menulist: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        ...shorthands.gap('0.5rem'),
        "> p": {
            color: "#000",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px"
        },
        ...shorthands.textDecoration("none"),
    },
    createProject: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

    actionsContainer: {
        display: "flex",
        ...shorthands.gap("2.5rem")
    },
    dropDownContainer: {
        color: "white",
        padding: ".4em .8em",
        background: "#5E5CE6",
        borderRadius: "8px"
    }

});