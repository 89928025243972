import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Switch, Text, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import Form from "@rjsf/fluentui-rc";
import validator from "@rjsf/validator-ajv8";
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { PluginResponse, PluginsService, ProjectAccessResponse, ProjectsService, WorkflowTemplateResponse, WorkflowTemplatesService, WorkflowsService } from '../services/openapi';
import { WorkflowCard } from './WorkflowCard';
import { PrimaryButton } from "./common/PrimaryButton";


interface Props {
    label?: string;
    style?: React.CSSProperties,
    className?: string,
    projectId: string;
    projectName?: string;
    flightId: string;
    flightName?: string;
    groupId?: string,
    appearance?: 'secondary' | 'primary' | 'outline' | 'subtle' | 'transparent';
    buttonID?: string
}

interface NodeSchema {
    nodeId: string;
    schema: any;
}

export const StartWorkflowButton = (props: Props) => {
    const [templates, setTemplates] = React.useState<WorkflowTemplateResponse[]>([]);
    const [selectedTemplateId, setSelectedTemplateId] = React.useState('');
    const [selectedGroupId, setSelectedGroupId] = React.useState(undefined as string | undefined);
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const [plugins, setPlugins] = React.useState<Array<PluginResponse>>();
    const [schemaList, setSchemaList] = React.useState<NodeSchema[]>([]);
    const [formValues, setFormValues] = React.useState<Record<string, string>>({});
    const [projectAccess, setProjectAccess] = React.useState<ProjectAccessResponse | null>(null);

    React.useEffect(() => {
        if (!props.projectId) return;
        ProjectsService.checkUserProjectAccess(props.projectId).then(permissions => {
            setProjectAccess(permissions)
        }).catch(err => console.error("Unable to fetch project access"))
    }, []);

    const canUploadData = !!projectAccess && projectAccess.canUploadData;

    const navigate = useNavigate();
    const classes = useStyles()


    const showDialog = () => {
        setSelectedTemplateId('');
        if (templates && templates.length) {
            setOpen(true);
            return;
        }
        WorkflowTemplatesService.getTemplates()
            .then((ts) => {
                setTemplates(ts.templates);
                setOpen(true);
            })
            .catch((err) => setError(`Could not get workflow templates: ${err}`));

        PluginsService.getPlugins()
            .then(plugins => setPlugins(plugins.plugins))
            .catch(err => console.error("ERROR GETTING PLUGINS : ", err));
    }

    const startWorflow = () => {
        if (!selectedTemplateId) return;
        if (selectedGroupId) {
            WorkflowsService.createGroupWorkflow(props.projectId, selectedGroupId, { templateId: selectedTemplateId, config: formValues })
                .then((wf) => {
                    setOpen(false);
                    navigate(`/workflows/${wf.id}`);
                })
                .catch((err) => setError(`Could not start workflow: ${err}`));
        } else {
            WorkflowsService.createFlightWorkflow(props.flightId, { templateId: selectedTemplateId, config: formValues })
                .then((wf) => {
                    setOpen(false);
                    navigate(`/workflows/${wf.id}`);
                })
                .catch((err) => setError(`Could not start workflow: ${err}`));
        }
    };

    const getSchema = (t: WorkflowTemplateResponse) => {
        if (!t || !t.json['nodes']) {
            return;
        };
        const nodesPlugins: string[] = t.json['nodes']
            .map((node: any) => node['plugin_id'] as string);
        if (!plugins || !nodesPlugins || !nodesPlugins.length) {
            return;
        };
        let schemas: NodeSchema[] = [];
        for (const node of (t.json['nodes'] || [])) {
            const pluginId = node['plugin_id'] as string;
            if (!pluginId) continue;
            const plugin = plugins.find(plugin => plugin.id === pluginId);
            if (!plugin) continue;
            if (
                plugin.id.endsWith('_UPLOADER')
                || plugin.id.endsWith('_PICKER')
            ) {
                continue;
            }

            let schema = plugin.configSchema;
            if (['METASHAPE', 'METASHAPE_W_GCPS', 'ALIGN', 'ALIGN_W_GCPS', 'ODM', 'MERGE'].indexOf(pluginId) > -1) {
                schema = {
                    "type": "object",
                    "properties": {
                        "quality": {
                            "type": "string",
                            "description": "Processing quality",
                            "default": "medium",
                            "oneOf": [
                                {
                                    "const": "low",
                                    "title": "Low"
                                },
                                {
                                    "const": "medium",
                                    "title": "Medium"
                                },
                                {
                                    "const": "high",
                                    "title": "High"
                                }
                            ],
                        }
                    },
                    "required": [
                        "quality"
                    ]
                };
            }
            if (!schema.hasOwnProperty("properties")) continue;
            if (Object.keys(schema.properties).length === 0) continue;
            console.log(pluginId, node['id'], schema);
            schemas.push({ nodeId: node['id'] as string, schema });
        }
        console.log('schemas', schemas);
        setSchemaList(schemas);
    };

    React.useEffect(() => {
        if (!open) {
            setFormValues({});
            setSchemaList([])
        }
    }, [open])

    if (!open) {
        return <span>
            {error}
            <PrimaryButton disabled={!canUploadData} label="Start New Workflow" onClick={() => showDialog()} id={props.buttonID} />
        </span>
    }

    const buttonText = (templates || []).find((t) => t.id === selectedTemplateId)?.name || '-- Select a workflow template --';
    const description = (templates || []).find((t) => t.id === selectedTemplateId)?.description || '';

    const isButtonDisabled = !selectedTemplateId

    return <Dialog modalType='non-modal' open={open} onOpenChange={(_, d) => setOpen(d.open)}>
        <DialogTrigger>
            <div>
                {error}
                <PrimaryButton label="Start New Workflow" />
            </div>
        </DialogTrigger>
        <DialogSurface>
            <DialogBody>
                <DialogTitle className={classes.title}>
                    {(props.projectName && props.flightName) ?
                        <>Initiate workflow for flight {props.flightName} in project {props.projectName}</>
                        : <>Initiate workflow</>
                    }

                </DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: "20px", marginTop: "20px" }}>
                    {props.groupId && (<div className={classes.select}>
                        <Switch
                            value={selectedGroupId}
                            onChange={(v, data) => {
                                if (data.checked) {
                                    setSelectedGroupId(props.groupId);
                                } else {
                                    setSelectedGroupId(undefined);
                                }
                            }}
                        /> Initiate workflow for Group ID {props.groupId}
                    </div>)}

                    <Menu positioning={'below-start'}>
                        <MenuTrigger     >
                            <div >
                                <Button size='large' style={{ width: "100%" }}>
                                    {buttonText}
                                </Button>
                                <div><Text size={200}>{description}</Text></div>
                            </div>
                        </MenuTrigger>
                        <MenuPopover style={{ width: "550px", maxWidth: "550px" }}>
                            <MenuList style={{ overflowY: 'auto', maxHeight: '20em' }}>
                                {templates.map((t) => {
                                    return <MenuItem
                                        key={t.id}
                                        style={{
                                            backgroundColor: tokens.colorNeutralBackground1,
                                            minWidth: "100%"
                                        }}
                                        onClick={() => { setSelectedTemplateId(t.id); getSchema(t); }}
                                    >
                                        <WorkflowCard template={t} />
                                    </MenuItem>
                                })}
                            </MenuList>
                        </MenuPopover>
                    </Menu>

                    {(schemaList && schemaList.length > 0) && <Accordion collapsible>
                        <AccordionItem value="1">
                            <AccordionHeader>Config</AccordionHeader>
                            <AccordionPanel >
                                <Accordion collapsible>
                                    {schemaList.map((nodeSchema, idx) =>
                                        <AccordionItem value={idx}>
                                            <AccordionHeader>{nodeSchema.nodeId}</AccordionHeader>
                                            <AccordionPanel >
                                                <Form
                                                    formData={formValues[nodeSchema.nodeId] || {}}
                                                    schema={nodeSchema.schema}
                                                    validator={validator}
                                                    onChange={(e) => {
                                                        const nodeForm = {} as any;
                                                        if (e.formData) {
                                                            nodeForm[nodeSchema.nodeId] = e.formData;
                                                        }
                                                        setFormValues(prev => ({
                                                            ...prev,
                                                            ...nodeForm,
                                                        }))
                                                    }}
                                                    uiSchema={uiSchema}
                                                />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    )}
                                </Accordion>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>}

                    <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', gap: "16px" }}>
                        <Button onClick={() => setOpen(false)} style={{ borderRadius: "8px" }}>Cancel</Button>
                        <PrimaryButton label="Start" onClick={startWorflow} disabled={isButtonDisabled} />
                    </div>
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}


const useStyles = makeStyles({
    title: {
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px",
    },
    select: {
        color: "#586A84",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
        display: "flex",
        ...shorthands.gap("6px"),
        alignItems: "center"
    },
    toaster: {
        width: "600px",
    },
})

const uiSchema = {
    "ui:submitButtonOptions": {
        norender: true
    },
    "file_type": {
        "ui:description": " "
    },
    "quality": {
        "ui:widget": "select",
        "ui:placeholder": "medium"
    }
}