import { Checkbox, Divider, Label, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Popover, PopoverSurface, PopoverTrigger, Slider, Toast, ToastTitle, Toaster, Tooltip, makeStyles, shorthands, useId, useToastController } from "@fluentui/react-components";
import { Map, View } from "ol";
import { Control, defaults } from 'ol/control.js';
import { EventsKey } from "ol/events";
import { Extent, createEmpty, extend, isEmpty } from "ol/extent";
import MVT from "ol/format/MVT";
import BaseLayer from "ol/layer/Base";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorTileLayer from "ol/layer/VectorTile";
import WebGLTileLayer from "ol/layer/WebGLTile";
import { unByKey } from 'ol/Observable';
import { fromLonLat } from "ol/proj";
import GeoTIFF from 'ol/source/GeoTIFF';
import OSM from "ol/source/OSM";
import VectorSource from "ol/source/Vector";
import VectorTileSource from 'ol/source/VectorTile';
import XYZ from "ol/source/XYZ";
import { useContext, useEffect, useRef, useState } from "react";
import ChevronDownIcon from "../assets/icons/chevron_down.svg";
import ChevronRightIcon from "../assets/icons/chevron_right.svg";
import AreaIcon from "../assets/icons/ol/measurement_tools/area.svg";
import AreaSelectedIcon from "../assets/icons/ol/measurement_tools/area_selected.svg";
import DistanceIcon from "../assets/icons/ol/measurement_tools/distance.svg";
import DistanceSelectedIcon from "../assets/icons/ol/measurement_tools/distance_selected.svg";
import PointIcon from "../assets/icons/ol/measurement_tools/point.svg";
import PointSelectedIcon from "../assets/icons/ol/measurement_tools/point_selected.svg";
import SelectIcon from "../assets/icons/ol/measurement_tools/select.svg";
import SelectSelectedIcon from "../assets/icons/ol/measurement_tools/select_selected.svg";
import DownloadIcon from "../assets/icons/ol_download.svg";
import FullScreenIcon from "../assets/icons/ol_fullscreen.svg";
import LayerIcon from "../assets/icons/ol_layers.svg";
import MinimizeIcon from "../assets/icons/ol_minimize.svg";
import LayerOpacityControl from "../assets/icons/ol_opacity_control.svg";
import ShareIcon from "../assets/icons/ol_share.svg";
import ZoomInIcon from "../assets/icons/ol_zoom_in.svg";
import ZoomOutIcon from "../assets/icons/ol_zoom_out.svg";
import LinkIcon from "../assets/icons/open_in_new.svg";
import ToastErrorIcon from "../assets/icons/toast_error.svg";
import { AuthContext } from "../AuthContext";
import { API_BASE } from "../constants";
import { MapLayerResponse, MeasurementResponse, MeasurementsResponse, UserResponse } from "../services/openapi";
import { AnnotationMenu } from "./measurement_tools/AnnotationMenu";
import { AnnotationPanel } from "./measurement_tools/AnnotationPanel";
import { handleAreaTool, handleDefaultTool, handleDistanceTool, handleElevationTool } from "./measurement_tools/utils/MeasurementToolChange";
import { drawMeasurements, drawMeasurementsByType } from "./measurement_tools/utils/OpenLayersDrawMeasurement";
interface OpenLayerProps {
    layers: MapLayerResponse[]
    onLoaded?: (map: Map) => void;
    showFullScreenIcon?: boolean
    fullScreenState?: boolean;
    showFullScreen?: () => void;
    hideFullScreen?: () => void;
    showToolBar?: boolean;
    showLayers?: boolean;
    mapContainerStyles?: React.CSSProperties;
    showDownload?: boolean;
    onClickDownload?: () => void;
    showShare?: boolean;
    onClickShare?: () => void
    showIdeaforgeButton?: boolean;
    shareID?: string | null;
    isShareOpen?: boolean;
    type?: "modal" | "non-modal"
    shareDisabled?: boolean;
    showMeasurements?: boolean;
    setFocus?: Extent | null
}

type layerItemsVisibilityConfigType = Record<string, Record<"opacityIcon", boolean>>
type toolsType = "area" | "distance" | "elevation" | "default"
export default function OpenLayers(props: OpenLayerProps) {
    const { layers, onLoaded, showFullScreenIcon = true, showFullScreen = () => null, hideFullScreen = () => null, fullScreenState = false, showToolBar = true, mapContainerStyles, showLayers = true, showDownload = false, showShare = false
        , onClickDownload = () => null, onClickShare = () => null, showIdeaforgeButton = false, shareID, isShareOpen = false, shareDisabled = false, type = "modal", showMeasurements = true, setFocus
    } = props
    const mapEl = useRef<HTMLDivElement>(null);
    const controlsRef = useRef<HTMLDivElement>(null);
    const [olMap, setOlMap] = useState<Map | null>(null);
    const [mapLayers, setMapLayers] = useState<Layer[]>([]);
    const classes = useStyles()
    const { me: auth } = useContext(AuthContext);
    const [baseZoomLevel, setBaseZoomLevel] = useState(0)
    const [showZoomLevel, setShowZoomLevel] = useState(false)
    const [p, setp] = useState(100)
    const [layerItemsVisibilityConfig, setLayerItemsVisibilityConfig] = useState<layerItemsVisibilityConfigType>({})
    const [currentView, setCurrentView] = useState<"2D" | "3D">("2D")
    const threeDLayers = layers.find((l) => l.layerType === 'PointCloud');
    const [draw, setDraw] = useState<any>(null);
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    // Measurement states
    const [measurements, setMeasurements] = useState<MeasurementResponse[]>([])
    const [selectedMeasurementTool, setSelectedToolMeasurementTool] = useState<toolsType | null>(null);
    const [currentDrawMeasurement, setCurrentDrawMeasurement] = useState<MeasurementResponse | null>(null);
    const [mapEventKeys, setMapEventKeys] = useState<{ singleEventKey: EventsKey | null }>({ singleEventKey: null })
    const [selectedMeasurement, setSelectedMeasurement] = useState<MeasurementResponse | null>(null);
    const [annotationsCollapsedData, setAnnotationsCollapsedData] = useState({
        annotation: { collapsed: false },
        area: { collapsed: false },
        distance: { collapsed: false },
        elevation: { collapsed: false },
    });
    const [hiddenMeasurements, setHiddenMeasurements] = useState<MeasurementResponse[]>([])
    const [currentLayer, setCurrentLayer] = useState<MapLayerResponse | null>(null)

    useEffect(() => {
        setSelectedMeasurement(null)
        if (!mapEl.current) return;
        let ls = createLayers(layers, auth, shareID);
        setMapLayers(ls.layers)

        const map = new Map({
            controls: defaults({
                zoom: false,
                rotate: false,
                attribution: false,
            }),
            target: mapEl.current,
            layers: ls.layers.map((l) => l.olLayer),
            view: new View({
                center: ls.center,
                zoom: Math.min(...ls.zooms),
            }),
            keyboardEventTarget: document,
        });

        const layerItemsVisibilityConfig: layerItemsVisibilityConfigType = {}
        ls.layers.forEach((lyr, index) => {
            layerItemsVisibilityConfig[`${lyr.olLayer.get("title")}${index}`] = {
                opacityIcon: false,
            }
        })
        setLayerItemsVisibilityConfig(layerItemsVisibilityConfig)
        setOlMap(map)
        if (onLoaded) {
            onLoaded(map)
        }
        setBaseZoomLevel(Math.min(...ls.zooms))
        return () => {
            map.setTarget(undefined);
        };
    }, [layers])

    useEffect(() => {
        if (olMap && setFocus && setFocus.length > 0) {
            olMap.getView().fit(setFocus, {
                duration: 1000,
                padding: [50, 50, 50, 50],
                maxZoom: 18,
            });
        }
    }, [setFocus])

    useEffect(() => {
        setSelectedMeasurement(null);
        const selectedLayers = mapLayers.filter(layer => (layer.hasOwnProperty("config") && layer.config?.layerType === "SingleChannel_COG"))
        if (selectedLayers.length >= 1) {
            const visibleLayersArray = selectedLayers.filter(layer => layer.olLayer.getVisible())
            if (visibleLayersArray.length === 1) {
                const id = visibleLayersArray[0].config?.id
                setCurrentLayer(layers.find(layer => layer.id === id) ?? null)
            } else {
                setCurrentLayer(null)
            }
        } else {
            setCurrentLayer(null)
        }
    }, [mapLayers, layers])

    useEffect(() => {
        if (olMap && controlsRef.current) {
            olMap.addControl(new Control({ element: controlsRef.current }));
        }
    }, [olMap, controlsRef]);

    useEffect(() => {
        if (showZoomLevel) {
            clearTimeout(hideTimer);
            hideTimer = setTimeout(() => {
                setShowZoomLevel(false);
            }, 2000);
        }
        return () => {
            clearTimeout(hideTimer);
        };
    }, [showZoomLevel]);

    const getAllMeasurementIDsBasedOnType = (measurements: MeasurementsResponse["measurements"], type: string) => {
        return measurements.filter(measurement => measurement.measurement_type === type).map(filteredMeasurement => filteredMeasurement.id)
    }

    const removeInteractionsAndEventsOnMap = () => {
        if (!olMap) return;
        olMap.removeInteraction(draw);
        if (mapEventKeys.singleEventKey) {
            unByKey(mapEventKeys.singleEventKey)
        }
    }

    useEffect(() => {
        if (!olMap) return;
        if (!currentLayer) {
            if (measurements.length > 0) {
                measurements.forEach(mID => {
                    clearDrawingsByID(mID.id)
                })
            }
            setSelectedToolMeasurementTool(null)
            removeInteractionsAndEventsOnMap()
            olMap.getViewport().style.cursor = 'default';
        } else {
            drawMeasurements(olMap, currentLayer, (measurements: MeasurementResponse[]) => {
                setMeasurements(measurements);
            })
        }

    }, [olMap, currentLayer])

    useEffect(() => {
        if (currentDrawMeasurement && olMap) {
            drawMeasurementsByType(currentDrawMeasurement, olMap);
            setCurrentDrawMeasurement(null);
            setSelectedMeasurement(currentDrawMeasurement);
        }
    }, [currentDrawMeasurement])

    const failureToast = (message: string) => dispatchToast(
        <Toast
            style={{ background: "rgba(253, 231, 233, 1)", width: "100%" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{message}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    const clearDrawingsByID = (id: string) => {
        if (!olMap) return;
        const layersToRemove: any[] = []
        olMap.getLayers().forEach((layer) => {
            if (layer instanceof VectorLayer) {
                const source = layer.getSource()
                if (source instanceof VectorSource) {
                    if (layer.getProperties()["tag"] === id) {
                        layersToRemove.push(layer)
                    }
                }
            }
        })
        layersToRemove.forEach(layer => {
            olMap.removeLayer(layer)
        })
    }

    const focusLayerByID = (id: string) => {
        if (!olMap) return;

        let combinedExtent = createEmpty();
        olMap.getLayers().forEach((layer) => {
            if (layer instanceof VectorLayer) {
                const source = layer.getSource();
                if (source instanceof VectorSource) {
                    const features = source.getFeatures();

                    features.forEach((feature) => {
                        if (feature.get('id') === id) {
                            const featureExtent = feature.getGeometry().getExtent();
                            extend(combinedExtent, featureExtent);
                        }
                    });
                }
            }
        });

        if (!isEmpty(combinedExtent)) {
            olMap.getView().fit(combinedExtent, {
                padding: [50, 50, 50, 500],
                duration: 1000,
                maxZoom: 20,
            });
        }
    };

    const hideMeasurementHandler = (measurementIDs: string[]) => {
        setHiddenMeasurements(prevHidden => {
            const measurementsToHide = measurements
                .filter(m => measurementIDs.includes(m.id) && !prevHidden.some(hidden => hidden.id === m.id));
            return [...prevHidden, ...measurementsToHide];
        });
        measurementIDs.forEach(mID => {
            clearDrawingsByID(mID)
        })
    };

    const showMeasurementHandler = (measurementIDs: string[]) => {
        setHiddenMeasurements(prevHidden => {
            return prevHidden.filter(m => !measurementIDs.includes(m.id));
        });
        if (!olMap) {
            return;
        }
        const measurementsToShow = measurements.filter(m => measurementIDs.includes(m.id))
        measurementsToShow.forEach(measurement => {
            drawMeasurementsByType(measurement, olMap)
        })
    };

    const onMeasurementToolChange = (tool: toolsType) => () => {
        if (!olMap) return;

        if (!currentLayer) {
            failureToast("Cannot access the measurement tools. Please select only one DEM layer.");
            return;
        }
        removeInteractionsAndEventsOnMap()
        setSelectedToolMeasurementTool(tool);

        const onDrawEndCallback = (measurement: MeasurementResponse) => {
            setMeasurements(prev => ([...prev, measurement]))
            setCurrentDrawMeasurement(measurement)
            removeInteractionsAndEventsOnMap();
        }

        switch (tool) {
            case "default":
                handleDefaultTool(olMap, {
                    hideAnnotation() {
                        setSelectedMeasurement(null);
                    },
                    listenersSetupCallback(singleEventKey) {
                        setMapEventKeys(prev => ({ ...prev, singleEventKey }))
                    },
                    showAnnotation(id) {
                        setSelectedMeasurement(measurements.find(m => m.id === id) ?? null)
                    },
                }); break;

            case "elevation": handleElevationTool(olMap, currentLayer.id, {
                onDrawEnd: onDrawEndCallback,
                onError() {
                    failureToast("Failed to get elevation for the point")
                },
                addInteration(draw) {
                    setDraw(draw)
                }
            }); break;

            case "distance": {
                handleDistanceTool(olMap, currentLayer.id, {
                    addInteration(draw) {
                        setDraw(draw)
                    },
                    onDrawEnd: onDrawEndCallback,
                    onError() {
                        failureToast("Unable to draw distance")
                    },
                });
                break;
            }

            case "area": {
                handleAreaTool(olMap, currentLayer.id, {
                    addInteration(draw) {
                        setDraw(draw)
                    },
                    onDrawEnd: onDrawEndCallback,
                    onError() {
                        failureToast("Failed to draw area")
                    },
                });
                break;
            }
        }
    }

    const calculatePercentage = () => {
        if (!olMap) {
            console.error("Map instance not found");
            return 100;
        }
        let currentZoom = olMap.getView().getZoom();
        if (currentZoom === undefined) {
            console.error("Couldn't get current zoom level")
            return 100
        }

        return 100 + ((currentZoom - baseZoomLevel) / baseZoomLevel) * 100
    }

    let hideTimer: any;

    const setZoom = (type: "in" | "out") => () => {
        if (!olMap) {
            return
        }
        let currentZoom = olMap.getView().getZoom()
        if (currentZoom === undefined) {
            return
        }
        olMap.getView().setZoom(type === "out" ? currentZoom - 1 : currentZoom + 1)
        if (showZoomLevel) {
            clearTimeout(hideTimer);
            hideTimer = setTimeout(() => {
                setShowZoomLevel(false);
            }, 2000);
        } else {
            setShowZoomLevel(true)
        }
        setp(calculatePercentage())
    }

    const deleteMeasurementCallback = (id: string) => {
        setMeasurements(prevMeasurements => {
            return prevMeasurements.filter(m => m.id !== id);
        });
        clearDrawingsByID(id)
        setSelectedMeasurement(null);
    }

    const updateMeasurementName = (id: string, newName: string) => {
        setMeasurements(prevMeasurements => {
            return prevMeasurements.map(m =>
                m.id === id ? { ...m, name: newName } : m
            );
        });
    };

    const updateMeasurementDescription = (id: string, newName: string) => {
        setMeasurements(prevMeasurements => {
            return prevMeasurements.map(m =>
                m.id === id ? { ...m, description: newName } : m
            );
        });
    };

    return <>
        <div className={classes.container} ref={mapEl} style={{ position: "relative", display: currentView === "2D" ? "block" : "none", overflow: "hidden", ...mapContainerStyles }}>
            <AnnotationPanel measurement={selectedMeasurement}
                deleteMeasurementCallback={deleteMeasurementCallback}
                hideMeasurementCallback={(id) => {
                    hideMeasurementHandler([id])
                }}
                measurementHidden={!!hiddenMeasurements.find(m => m.id === selectedMeasurement?.id)}
                focusMeasurementCallback={((id) => {
                    focusLayerByID(id)
                })}
                showMeasurementCallback={(id) => {
                    showMeasurementHandler([id])
                }}
                onChangeName={(val) => {
                    if (selectedMeasurement) {
                        updateMeasurementName(selectedMeasurement.id, val)
                    }
                }}
                onChangeDescription={(val) => {
                    if (selectedMeasurement) {
                        updateMeasurementDescription(selectedMeasurement.id, val)
                    }
                }}
                name={measurements.find(m => m.id === selectedMeasurement?.id)?.name ?? ""}
                description={measurements.find(m => m.id === selectedMeasurement?.id)?.description ?? ""}
                onDeleteError={() => {
                    failureToast("Failed to delete measurement")
                }}
                onNameChangeError={() => {
                    failureToast("Failed to update name")
                }}
                onDescriptionChangeError={() => {
                    failureToast("Failed to update description")
                }}

            />
            <Toaster toasterId={toasterId} position="bottom" limit={1} className={classes.toaster} />
        </div>

        {showToolBar && <div ref={controlsRef}
            className={classes.toolboxContainer}
            style={{ pointerEvents: "none", paddingBottom: type === "modal" ? "40px" : "100px", paddingTop: type === "modal" ? "40px" : "100px" }}
        >
            <div className={classes.toolboxVertical}>
                {showLayers && <>
                    <Menu positioning={"before-top"} persistOnItemClick>
                        <MenuTrigger disableButtonEnhancement>
                            <Tooltip content="Layers" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                                <img src={LayerIcon} alt="layers" className={classes.icon} />
                            </Tooltip>
                        </MenuTrigger>

                        <MenuPopover className={classes.layersPopover}>
                            <MenuList className={classes.layersMenu}>
                                {mapLayers.map((lyr: Layer, index) => {
                                    const l = lyr.olLayer;
                                    const title = l.get('title')
                                    const key = `${title}${index}`
                                    return <div key={key}
                                        onMouseEnter={() => {
                                            setLayerItemsVisibilityConfig(prev => {
                                                return {
                                                    ...prev,
                                                    [key]: {
                                                        ...prev[key],
                                                        opacityIcon: true,
                                                    }
                                                }
                                            })
                                        }}
                                        onMouseLeave={() => {
                                            setLayerItemsVisibilityConfig(prev => {
                                                return {
                                                    ...prev,
                                                    [key]: {
                                                        ...prev[key],
                                                        opacityIcon: false,
                                                    }
                                                }
                                            })
                                        }}
                                    >
                                        <MenuItem style={{ maxHeight: "32px" }}>
                                            <div className={classes.layerItem}>
                                                <Checkbox size='large'
                                                    className={classes.checkbox}
                                                    onChange={(e) => {
                                                        l.setVisible(e.target.checked);
                                                        setMapLayers([...mapLayers]);
                                                    }}
                                                    checked={l.getVisible()}
                                                />


                                                <div className={classes.layerItemTextContainer}>
                                                    <p className={classes.layerTitle}>{title}</p>


                                                    {Boolean(layerItemsVisibilityConfig[key].opacityIcon) && <>
                                                        <Popover positioning={"before"}>
                                                            <PopoverTrigger>
                                                                <img src={LayerOpacityControl} alt="opacity control" />
                                                            </PopoverTrigger>

                                                            <PopoverSurface tabIndex={-1} style={{ padding: 0 }}>
                                                                <Slider
                                                                    min={0}
                                                                    max={100}
                                                                    value={l.getOpacity() * 100}
                                                                    onChange={(_, v) => {
                                                                        l.setOpacity(v.value / 100.0);
                                                                        setMapLayers([...mapLayers]);
                                                                    }}
                                                                />
                                                            </PopoverSurface>
                                                        </Popover>
                                                    </>}
                                                </div>
                                            </div>
                                        </MenuItem>

                                        {(lyr.config?.layerType === 'SingleChannel' || lyr.config?.layerType === 'SingleChannel_COG') && (
                                            <div className={classes.layerItem} style={{ alignItems: "center" }}>
                                                <p className={classes.layerTitle}>Threshold</p>
                                                <div className={classes.thresholdSliderWrapper}>
                                                    <Label aria-hidden>{Math.trunc(lyr.config?.config.layer.stats[0].min)}</Label>
                                                    <Slider
                                                        min={0}
                                                        max={100}
                                                        value={(lyr.variables.threshold || 0) * 100}
                                                        onChange={(_, v) => {
                                                            (l as WebGLTileLayer).updateStyleVariables({ threshold: v.value / 100.0 })
                                                            setMapLayers([...mapLayers]);
                                                        }}
                                                    />
                                                    <Label aria-hidden>{Math.trunc(lyr.config?.config.layer.stats[0].max)}</Label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                })}
                                {/* Annotations Header */}
                                {Boolean(measurements && measurements.length > 0 && currentLayer) && <MenuItem style={{ paddingLeft: "0" }}>
                                    <div className={classes.layerHeaderItem}>
                                        <img src={annotationsCollapsedData.annotation.collapsed ? ChevronRightIcon : ChevronDownIcon} height="24px" width="24px" onClick={() => {
                                            setAnnotationsCollapsedData(prev => ({ ...prev, annotation: { collapsed: !prev.annotation.collapsed } }))
                                        }} />

                                        <Checkbox size='large'
                                            className={classes.checkbox}
                                            onChange={(e) => {
                                                if (!e.target.checked) {
                                                    hideMeasurementHandler(measurements.map(m => m.id))
                                                } else {
                                                    showMeasurementHandler(measurements.map(m => m.id))
                                                }
                                            }}
                                            defaultChecked={true}
                                            disabled={!currentLayer}
                                        />
                                        <p className={classes.layerHeaderTitle}>Annotation</p>
                                    </div>
                                </MenuItem>}

                                {Boolean(currentLayer && !annotationsCollapsedData.annotation.collapsed) && <>
                                    {(["distance", "area", "elevation"] as const).map((type) => {
                                        return <AnnotationMenu
                                            measurementType={type}
                                            measurements={measurements}
                                            collapsed={annotationsCollapsedData[type].collapsed}
                                            onCollapseToggle={() => {
                                                setAnnotationsCollapsedData(prev => ({ ...prev, [type]: { collapsed: !prev[type].collapsed } }))
                                            }}
                                            onParentCheck={() => { showMeasurementHandler(getAllMeasurementIDsBasedOnType(measurements, type)) }}
                                            onParentUncheck={() => { hideMeasurementHandler(getAllMeasurementIDsBasedOnType(measurements, type)) }}
                                            onChildCheck={(mID: string) => { showMeasurementHandler([mID]) }}
                                            onChildUncheck={(mID: string) => { hideMeasurementHandler([mID]) }}
                                            onClickLabel={(measurement) => { setSelectedMeasurement(measurement) }}
                                            hiddenMeasurements={hiddenMeasurements}
                                            disabled={!currentLayer}
                                        />
                                    })}
                                </>}
                            </MenuList>
                        </MenuPopover>
                    </Menu></>}

                {showShare && <>  <Divider className={classes.divider} />
                    <Tooltip content="Share Map" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                        <img src={ShareIcon} alt="share" className={classes.icon} onClick={onClickShare} style={{ opacity: shareDisabled ? "50%" : "100%" }} />
                    </Tooltip>
                </>}
            </div >

            <div style={{ marginTop: "40px" }} />

            {/* Annotations */}
            {showMeasurements && <div className={classes.toolboxVertical}>
                <Tooltip content="Select" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0} >
                    <img src={selectedMeasurementTool === "default" ? SelectSelectedIcon : SelectIcon} alt="select" className={classes.icon} onClick={onMeasurementToolChange("default")} />
                </Tooltip>

                <Divider className={classes.divider} />

                <Tooltip content="Distance" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                    <img src={selectedMeasurementTool === "distance" ? DistanceSelectedIcon : DistanceIcon} alt="distance" className={classes.icon} onClick={onMeasurementToolChange("distance")} />
                </Tooltip>

                <Divider className={classes.divider} />

                <Tooltip content="Area" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                    <img src={selectedMeasurementTool === "area" ? AreaSelectedIcon : AreaIcon} alt="area" className={classes.icon} onClick={onMeasurementToolChange("area")} />
                </Tooltip>

                <Divider className={classes.divider} />

                {!!layers.find(item => (item.layerType === "SingleChannel" || item.layerType === "SingleChannel_COG")) && <Tooltip content="Elevation" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                    <img src={selectedMeasurementTool === "elevation" ? PointSelectedIcon : PointIcon} alt="elevation" className={classes.icon} onClick={onMeasurementToolChange("elevation")} />
                </Tooltip>}
            </div>
            }
            <div style={{ marginTop: "40px" }} />

            <div className={classes.bottomToolbarContainer}>
                {(threeDLayers && !isShareOpen) && <div className={classes.toolboxHorizontal}>
                    <button className={classes.toggleItem} onClick={() => { setCurrentView("3D") }} style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}>
                        <p className={classes.toggleItemText}
                            style={{
                                color: currentView === "3D" ? "black" : "rgba(130, 146, 168, 1)",
                                borderColor: currentView === "3D" ? "black" : "rgba(130, 146, 168, 1)"
                            }}>
                            3D</p>
                    </button>
                    <button className={classes.toggleItem} onClick={() => { setCurrentView("2D") }} style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}>
                        <p className={classes.toggleItemText}
                            style={{
                                color: currentView === "2D" ? "black" : "rgba(130, 146, 168, 1)",
                                borderColor: currentView === "2D" ? "black" : "rgba(130, 146, 168, 1)"
                            }}>
                            2D</p>
                    </button>
                </div>}

                {showDownload && <div className={classes.toolboxVertical} onClick={onClickDownload}>
                    <Tooltip content="Download Map" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                        <img src={DownloadIcon} alt="download map" className={classes.icon} />
                    </Tooltip>
                </div>}

                <div className={classes.toolboxHorizontal}>
                    <Tooltip content="Zoom in" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0} >
                        <img src={ZoomInIcon} alt="zoom in" className={classes.icon} onClick={setZoom("in")} />
                    </Tooltip>

                    <Divider vertical />

                    <Tooltip content="Zoom out" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                        <img src={ZoomOutIcon} alt="zoom out" className={classes.icon} onClick={setZoom("out")} />
                    </Tooltip>
                </div>

                {showFullScreenIcon && <>
                    {!fullScreenState && <div className={classes.toolboxVertical} onClick={showFullScreen}>
                        <img src={FullScreenIcon} alt="full screen" className={classes.icon} />
                    </div>}

                    {fullScreenState && <div className={classes.toolboxVertical} onClick={hideFullScreen}>
                        <img src={MinimizeIcon} alt="minimize" className={classes.icon} />
                    </div>}
                </>}
            </div>
        </div >}

        {
            (currentView === "3D") && <div className={classes.toggleView}>
                <button className={classes.toggleItem} onClick={() => { setCurrentView("3D") }} style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}>
                    <p className={classes.toggleItemText}
                        style={{
                            color: "black",
                            borderColor: "black",
                        }}>
                        3D</p>
                </button>
                <button className={classes.toggleItem} onClick={() => { setCurrentView("2D") }} style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}>
                    <p className={classes.toggleItemText}
                        style={{
                            color: "rgba(130, 146, 168, 1)",
                            borderColor: "rgba(130, 146, 168, 1)"
                        }}>
                        2D</p>
                </button>
            </div>
        }


        {
            currentView === "3D" && <>
                <iframe
                    style={{ width: '100%', height: '100%' }}
                    src={`/potree/pages/viewer.html?path=${API_BASE}/api/map_layers/${threeDLayers?.id}/files/cloud.js&token=${auth?.token}`}
                >
                </iframe>
            </>
        }

        {
            showIdeaforgeButton && <div className={classes.ideaforgeWebsiteButton} onClick={() => { window.open('https://ideaforgetech.com/ ', '_blank') }}>
                <p> ideaforgetech.com</p>
                <img src={LinkIcon} alt='open ideaforge website' />
            </div>
        }

        <div className={classes.zoomLevelContainer} style={{ opacity: showZoomLevel ? "1" : "0", transition: "all 0.25s" }}>
            <div className={classes.zoomLevel}>{`${calculatePercentage().toFixed()}% Zoom`}</div>
        </div>

        {/* <Toaster toasterId={toasterId} /> */}
    </>
}


const useStyles = makeStyles({
    container: {
        height: "100%",
        width: "100%",
    },
    toaster: {
        width: "600px",
    },
    toolboxContainer: {
        position: 'absolute',
        right: '20px',
        height: "100%",
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "space-between",
        alignItems: "flex-end",
        userSelect: "none",
    },
    toolboxVertical: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "white"
    },
    toolboxHorizontal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "white"
    },
    bottomToolbarContainer: {
        display: 'flex',
        alignItems: "flex-end",
        ...shorthands.gap("40px")
    },
    icon: {
        ...shorthands.padding("8px"),
        cursor: "pointer",
        pointerEvents: "auto",
    },
    divider: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    fullScreen: {
        position: 'absolute',
        bottom: '16px',
        right: '20px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        ...shorthands.borderRadius("8px"),
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
    },
    toggleView: {
        position: 'absolute',
        bottom: '40px',
        right: '20px',
        backgroundColor: "#FFF",
        ...shorthands.borderRadius("8px"),
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    toggleItem: {
        cursor: "pointer",
        ...shorthands.border("none"),
        ...shorthands.overflow("hidden"),
        ...shorthands.padding("10px", "8px"),
        backgroundColor: "#FFF",
        ":hover": {
            backgroundColor: "#EEE",
        },
    },
    toggleItemText: {
        ...shorthands.border("2px", "solid", "rgba(130, 146, 168, 1)"),
        ...shorthands.padding("0px", "4px"),
        ...shorthands.borderRadius("4px"),
        fontWeight: "bold",
        fontSize: "12px",
        pointerEvents: "auto",
    },
    zoomLevelContainer: {
        position: 'absolute',
        bottom: '32px',
        right: "50%",
        transform: "traslateX(50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

    },
    zoomLevel: {
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingRight: "8px",
        paddingLeft: "8px",
        color: "#FFF",
        backgroundColor: "rgba(0, 0, 0, 0.50)",
        ...shorthands.borderRadius("4px"),
    },
    layersPopover: {
        left: "-8px !important",
    },
    checkbox: {
        '> div': {
            ...shorthands.borderRadius("3.5px"),
            ...shorthands.margin(0),
        },
        '> input': {
            width: "100%",
        },
    },
    layersMenu: {
        maxHeight: "288px",
        overflowY: "auto",
        scrollbarWidth: 'thin',
        scrollbarColor: '#A9B4C5 transparent',
    },
    layerTitle: {
        color: "rgba(0, 0, 0, 0.90)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px"
    },
    layerItem: {
        display: "flex",
        flexDirection: "row",
        ...shorthands.gap("0.5rem"),
        width: "224px",
    },
    layerHeaderItem: {
        display: 'flex',
        flexDirection: "row",
    },
    layerHeaderTitle: {
        marginLeft: "8px",
    },
    layerItemTextContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    thresholdSliderWrapper: {
        display: 'flex',
        alignItems: "center"
    },
    ideaforgeWebsiteButton: {
        position: 'absolute',
        bottom: '16px',
        left: '20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...shorthands.gap("8px"),
        fontSize: "16px",
        lineHeight: "24px",
        cursor: "pointer",
        backgroundColor: "#FFF",
        color: "black",
        ...shorthands.padding("8px", "16px"),
        '> img': {
            width: "15px",
            height: "15px",
        },
        ':hover': {
            backgroundColor: "#C6FFCA",
            boxShadow: "-1px 1px 10px 0px rgba(0, 0, 0, 0.25)"
        },
        ...shorthands.borderRadius("0.5rem"),
    },

})

interface Layer {
    olLayer: BaseLayer,
    config?: MapLayerResponse,
    variables: { threshold?: number },
}

const createLayers = (layers: MapLayerResponse[], auth: UserResponse | null, shareID?: string | null) => {
    const ls: Layer[] = [
        {
            olLayer:
                new TileLayer({
                    visible: true,
                    title: 'Base',
                    source: new OSM({
                        cacheSize: 2048,
                    }),
                    zIndex: 0,
                } as any),
            variables: {},
        }
    ];
    let center: any[] = [0, 0];
    let zooms: any[] = [];
    let idx = 0;
    let view: any;
    for (const layer of layers) {
        const layerConfig = layer.config.layer;
        if (!layerConfig) continue;
        const bl = fromLonLat([layerConfig['bl']['lon'], layerConfig['bl']['lat']], 'EPSG:3857');
        const tr = fromLonLat([layerConfig['tr']['lon'], layerConfig['tr']['lat']], 'EPSG:3857');
        center = fromLonLat([layerConfig['center']['lon'], layerConfig['center']['lat']], 'EPSG:3857');
        const title = layer.config['title'] || layer.layerType;
        const variables = { threshold: 0.00 };

        idx = idx + 1;
        let l: BaseLayer;
        if (layer.layerType !== 'VectorTiles') {
            zooms.push(layerConfig['minZoom']);
            zooms.push(layerConfig['maxZoom']);
        }
        switch (layer.layerType) {
            case 'VectorTiles': {
                l = new VectorTileLayer({
                    declutter: false,
                    source: new VectorTileSource({
                        format: new MVT({
                            defaultDataProjection: 'EPSG:4326',
                            cacheSize: 2048,
                        } as any),
                        url: `${API_BASE}/api/map_layers/${layer.id}/files/{z}/{x}/{y}.pbf${shareID ? `?shareId=${shareID}` : `?token=${auth?.token}`}`,
                    }),
                    zIndex: 100 + idx,
                });
                break;
            }
            case 'SingleChannel': {
                const normalize = (e: any) => {
                    return ['/', e, 1];
                };
                const normalizedValue = normalize(['band', 1]);
                l = new WebGLTileLayer({
                    visible: true,
                    opacity: 0.8,
                    extent: [bl[0], bl[1], tr[0], tr[1]],
                    source: new XYZ({
                        attributions: 'ideaForge FlyghtCloud',
                        minZoom: layerConfig['minZoom'],
                        maxZoom: layerConfig['maxZoom'],
                        url: `${API_BASE}/api/map_layers/${layer.id}/files/{z}/{x}/{-y}.png${shareID ? `?shareId=${shareID}` : `?token=${auth?.token}`}`,
                        tileSize: [layerConfig['tileSize']['width'], layerConfig['tileSize']['height']],
                        cacheSize: 2048,
                    }),
                    zIndex: idx,
                    style: {
                        color: [
                            'array',
                            normalizedValue,
                            normalizedValue,
                            normalizedValue,
                            [
                                'case',
                                ['<=', normalizedValue, ['var', 'threshold']],
                                0,
                                1,
                            ],
                        ],
                        variables,
                    },
                });
                break;
            }
            case 'SingleChannel_COG': {
                const source = new GeoTIFF({
                    normalize: true,
                    sources: [
                        {
                            bands: [1],
                            min: layer.config.layer.stats[0].min,
                            max: layer.config.layer.stats[0].max,
                            url: `${API_BASE}/api/map_layers/${layer.id}/files/cog_4326.tif${shareID ? `?shareId=${shareID}` : ''}`
                        },
                    ],
                    sourceOptions: {
                        credentials: 'include'
                    }
                });
                const normalize = (e: any) => {
                    return e;
                };
                const normalizedValue = normalize(['band', 1]);
                l = new WebGLTileLayer({
                    visible: true,
                    opacity: 0.8,
                    source,
                    zIndex: idx,
                    style: {
                        color: [
                            'array',
                            normalizedValue,
                            normalizedValue,
                            normalizedValue,
                            [
                                'case',
                                ['<=', normalizedValue, ['var', 'threshold']],
                                0,
                                1,
                            ],
                        ],
                        variables,
                    },
                });
                view = source.getView();
                break;
            }
            case 'RGBA': {
                l = new TileLayer({
                    visible: true,
                    opacity: 0.8,
                    extent: [bl[0], bl[1], tr[0], tr[1]],
                    source: new XYZ({
                        attributions: 'ideaForge FlyghtCloud',
                        minZoom: layerConfig['minZoom'],
                        maxZoom: layerConfig['maxZoom'],
                        url: `${API_BASE}/api/map_layers/${layer.id}/files/{z}/{x}/{-y}.png${shareID ? `?shareId=${shareID}` : ''}`,
                        tileSize: [layerConfig['tileSize']['width'], layerConfig['tileSize']['height']],
                        cacheSize: 2048,
                    } as any),
                    zIndex: 50 + idx,
                });
                break;
            }
            case 'RGBA_COG': {
                const source = new GeoTIFF({
                    sources: [
                        {
                            url: `${API_BASE}/api/map_layers/${layer.id}/files/cog_4326.tif${shareID ? `?shareId=${shareID}` : ''}`
                        },
                    ],
                    sourceOptions: {
                        credentials: 'include'
                    }
                });
                l = new WebGLTileLayer({
                    visible: true,
                    opacity: 0.8,
                    source: source,
                    zIndex: 50 + idx,
                });
                view = source.getView();
                break;
            }
            default: {
                continue;
            }
        }
        l.set('title', title);
        const lyr = { olLayer: l, config: layer, variables };
        ls.push(lyr);
    }

    if (zooms.length === 0) {
        // if only vector layers, use some sensible zoom level for initial
        zooms.push(16);
    }

    return { layers: ls, center, zooms, view };
};
