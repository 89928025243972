import { Body1, Card, CardHeader, CardPreview, makeStyles } from '@fluentui/react-components';
import React from 'react'

interface Props {
    icon: string;
    title: string;
    info: string
}

function WhatsNewCard({ icon, title, info }: Props) {
    const classes = useStyles()
    return (
        <Card
            className={classes.card}
            focusMode="off"
        >
            <CardPreview>
                <img
                    src={icon}
                    alt={title}
                />
            </CardPreview>

            <CardHeader style={{ padding: "0 1.5em" }}
                header={
                    <Body1>
                        <b className={classes.title}>{title}</b>
                        <p className={classes.info}>
                            {info}
                        </p>
                    </Body1>
                }
            />
        </Card>
    )
}

export default WhatsNewCard

const useStyles = makeStyles({
    card: {
        width: "700px",
        maxWidth: "100%",
        height: "fit-content",
        borderRadius: "20px",
        background: "white"
    },
    title: {
        fontSize: "2rem",
        lineHeight: "2rem",
        fontWeight: "600",
        marginTop: ".5em"
    },
    info: {
        fontSize: "1rem",
        lineHeight: "1.2rem",
        fontWeight: "500",
        margin: "1em 0"
    }
})