import { Button, makeStyles, shorthands, Toast, Toaster, ToastTitle, tokens, useToastController } from "@fluentui/react-components";
import React from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import projectHeaderIcon from "../../assets/icons/project_header.svg";
import { FeatureDemosService, FlightResponse, FlightsService, ProjectAccessResponse, ProjectResponse, ProjectsService } from '../../services/openapi';
import { ListCard, } from '../ListCard';
import { Loading } from '../Loading';
import { PageNotFound } from "../PageNotFound";
import { sourceTypes, TourContext } from "../TourContext";
import { getTourSteps } from "../TourSteps";
import { PrimaryButton } from "../common/PrimaryButton";
import { FlightCard } from './FlightCard';
import FileManageWrapper from "./fileManage/FileManageWrapper";


export const Project = () => {
    const classes = useStyles();
    const [project, setProject] = React.useState<ProjectResponse | null>();
    const [flights, setFlights] = React.useState<FlightResponse[] | null>();
    const [manageFilesModalVisible, setManageFilesModalVisible] = React.useState(false);
    const [projectAccess, setProjectAccess] = React.useState<ProjectAccessResponse | null>(null)
    const navigate = useNavigate();
    const { dispatchToast } = useToastController("success");
    const { startTour, source, setTourSource, isPrevious } = React.useContext(TourContext);
    const location = useLocation();

    const { projectId } = useParams();
    const getProjectSummary = (pid: string) => {
        ProjectsService.getProjectSummary(pid)
            .then((ps) => setProject(ps))
            .catch((err) => console.error(err));
    }

    React.useEffect(() => {
        const stepIndexMapViewed = getTourSteps().findIndex(item => item.target === "#flight-row")
        const stepindexMapNotViewed = getTourSteps().findIndex(item => item.target === "#flight-actions-header")
        if (source && source === sourceTypes.startOnboarding && !isPrevious) {
            const tour = localStorage.getItem("tour")
            if (tour && JSON.parse(tour).mapviewed) {
                startTour({ run: true, stepIndex: stepIndexMapViewed });
            } else {
                startTour({ run: true, stepIndex: stepindexMapNotViewed });
            }
            setTourSource(sourceTypes.startOnboarding);
            return;
        }
        if (flights) {
            FeatureDemosService.getAllFeatureDemos().then((features) => {
                if (!(features?.featureDemos.find(item => item.name === "projects"))) {
                    return;
                } else {
                    FeatureDemosService.getAllFeatureDemoViews().then((features) => {
                        if (features?.viewedFeatureDemos.find(item => item.name === "projects")) {
                            return;
                        }
                        if (!(features?.viewedFeatureDemos.find(item => item.name === "map"))) {
                            startTour({ run: true, stepIndex: stepindexMapNotViewed })
                        } else {
                            startTour({ run: true, stepIndex: stepIndexMapViewed })
                        }
                    });
                }
            })
        }
    }, [location.pathname, flights])


    React.useEffect(() => {
        if (!projectId) return;
        ProjectsService.checkUserProjectAccess(projectId).then(permissions => {
            setProjectAccess(permissions)
        }).catch(err => console.error("Unable to fetch project access"))
    }, []);

    const canView = !!projectAccess && projectAccess.canViewProject;
    const canCreateFlight = !!projectAccess && projectAccess.canCreateFlight;
    const canArchive = !!projectAccess && projectAccess.canArchiveFlight;
    const canUploadData = !!projectAccess && projectAccess.canUploadData;
    const canEdit = projectAccess && projectAccess.canEditProject;

    React.useEffect(() => {
        if (!projectId) return;
        getProjectSummary(projectId);
        getFilghtData(projectId)
    }, [projectId])

    const getFilghtData = (projectId: string) => {
        FlightsService.getFlights(projectId)
            .then((fs) => setFlights(fs.flights))
            .catch((err) => console.error(err));
    }

    const loaded = !!project && !!flights;


    if (!loaded) {
        return <Loading />;
    }

    const toggleManageFilesModal = (flag: boolean) => {
        setManageFilesModalVisible(flag);
    };

    if (!canView) {
        return <PageNotFound />
    }

    const notifySucess = (msg: string) => dispatchToast(
        <Toast
            style={{ background: "#DFF6DD", maxWidth: "600px" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{msg}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    return <>
        <div className={classes.projectHeaderText}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link style={{ textDecoration: "none", color: "black", fontWeight: "normal" }} className={classes.flexContent} to="/projects"><img alt="projectIcon" src={projectHeaderIcon} /> Projects</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>{project.name}</div>
        </div>
        <div
            style={{
                width: '98%',
                background: tokens.colorNeutralBackground1,
                borderRadius: '1em',
                marginTop: "1.5em",
                marginLeft: "auto",
                marginRight: 'auto',
                boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)',
                paddingBottom: "1em",
                paddingLeft: "1em",
                paddingRight: "1em"
            }}
        >
            <ListCard>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }} id="flight-actions-header">
                    <h1 className={classes.header}>Flights</h1>
                    <div style={{ display: "flex", gap: "16px" }}>
                        {!!project.numMaps && (
                            <Button appearance="secondary" style={{ borderRadius: "8px" }} onClick={() => navigate(`/projects/${project.id}/views`)} id="flight-actions-viewmaps">Maps</Button>
                        )}
                        <Button disabled={!canEdit} appearance="secondary" style={{ borderRadius: "8px" }}
                            onClick={() => {
                                if (canEdit) {
                                    toggleManageFilesModal(true)
                                }
                            }} id="flight-actions-managefiles"
                        >Manage Project Files</Button>
                        <PrimaryButton id="flight-actions-addflight" label="Add a new flight" onClick={() => navigate(`/projects/${project.id}/new-flight`)} disabled={!canCreateFlight} />
                    </div >
                </div >
                <div style={{ zIndex: 999999999 }} className={classes.toasterLayer}>
                    <Toaster inline toasterId={"success"} position="bottom" />
                </div>
            </ListCard >
            <FlightCard flightData={flights} showWorkflowButton={true} getFlightData={getFilghtData} canArchive={canArchive} canUploadData={canUploadData} />
        </div >

        {project && manageFilesModalVisible && projectId && (
            <FileManageWrapper
                projectId={projectId}
                fmWrapperVisible={manageFilesModalVisible}
                toggleFmWrapperModal={setManageFilesModalVisible}
                notifySucess={notifySucess}
            />
        )}

    </>;
};
const useStyles = makeStyles({
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom('1px', 'solid', '#E3E9F2'),
        '> span': {
            fontWeight: "700",
        }
    },
    header: {
        color: "#586A84",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px"
    },
    toasterLayer: {
        '& .f1euv43f': {
            width: "45% !important",
            left: " calc(53% + 0px) !important"
        },
    }
})