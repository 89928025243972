import { makeStyles, shorthands, Toast, Toaster, ToastTitle, tokens, useId, useToastController } from "@fluentui/react-components";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ToastErrorIcon from "../assets/icons/toast_error.svg";
import ideaforgeLogoSmall from "../assets/logo/ideaforge_small.svg";
import { AuthContext } from "../AuthContext";
import { AuthService, SsoService } from "../services/openapi";
import { ApprovalPending } from "./auth/ApprovalPending";
import { ForgotPassword } from "./auth/ForgotPassword";
import { ForgotPasswordVerification } from "./auth/ForgotPasswordVerification";
import { Login } from "./auth/Login";
import { OTPVerfication } from "./auth/OTPVerfication";
import { Signup } from "./auth/Signup";
import HeaderNavBar from "./landing/common/HeaderNavBar";
import flyghtcloud_logo from "../assets/icons/landing/logo_white.svg"
import home_bg from "../assets/icons/landing/homeBG.webp"
import useIsMobile from "./hooks/useIsMobile";
import MobileHeader from "./landing/common/MobileHeader";


interface SignUpProps {
    fullName: string,
    email: string,
    password: string,
    confirmPassword: string
}
interface ForgotPAsswordProps {
    email: string,
}

interface ForgotPasswordVerificationProps {
    otp: string,
    password: string,
    confirmPassword: string
}

export default function LoginPage() {
    const classes = useStyles();
    const timeout = 2000;
    const toasterId = useId("toaster");
    const isMobile = useIsMobile()
    const { dispatchToast } = useToastController(toasterId);
    const initialSignUpValues = { fullName: '', email: '', password: '', confirmPassword: '' };
    const initiaForgotPasswordValues = { email: '', };
    const initiaFPWValues = { otp: '', password: '', confirmPassword: '' };
    const [isAuthForm, setIsAuthForm] = React.useState<'login' | string>('login');
    const [signUpValues, setSignUpValues] = React.useState<SignUpProps>(initialSignUpValues);
    const [forgotPasswordValues, setForgotPasswordValues] = React.useState<ForgotPAsswordProps>(initiaForgotPasswordValues);
    const [forgotPasswordVerificationValues, setForgotPasswordVerificationValues] = React.useState<ForgotPasswordVerificationProps>(initiaFPWValues);
    const [isSignUpDisabled, setIsSignUpDisabled] = React.useState(false);
    const [isOtpVerificationBtn, setIsOtpVerificationBtn] = React.useState(false);
    const [isForgotDisabaled, setIsForgotDisabaled] = React.useState(false);
    const [isForgotPasswordVerification, setIsForgotPasswordVerification] = React.useState(false);
    const navigate = useNavigate();
    const [isLoginDisabled, setIsLoginDisabled] = React.useState(false);
    const loggedMe = React.useContext(AuthContext);
    const [params] = useSearchParams();

    console.log("LOCATION", params.get("attempt_id"));

    useEffect(() => {
        if (loggedMe?.me) {
            navigate("/dashboard");
        }
    }, [loggedMe])

    const notifyError = (err: string) => dispatchToast(
        <Toast
            style={{ background: "#FDE7E9" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{err}</ToastTitle>
        </Toast>,
        { timeout, intent: "error" }
    );

    const notifySucess = (msg: string) => dispatchToast(
        <Toast
            style={{ background: "#DCF7EFEF" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{msg}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const handleGoBack = (formType: string) => {
        setIsAuthForm(formType)
    }
    type SignUpRequest = {
        email: string;
        fullName: string;
        password: string;
        confirmPassword: string;
    };
    const handleSignUp = ({ fullName, email, password, }: SignUpRequest) => {
        setIsSignUpDisabled(true)
        const signUpData = {
            name: fullName,
            email,
            password,
        };
        AuthService.signUp(signUpData)
            .then((res) => {
                setIsSignUpDisabled(false)
                setIsAuthForm('otpVerification')
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsSignUpDisabled(false)
                }, timeout);
                notifyError('This email is already in use. Please log in or try another')
            })
    }
    const handleForgotPassword = (formValues: any) => {
        setIsForgotDisabaled(true)
        AuthService.resetPassword(formValues)
            .then((res) => {
                setIsForgotDisabaled(false)
                setIsAuthForm('forgotPasswordVerification')
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsForgotDisabaled(false)
                }, timeout);
                notifyError('The entered email does not exist.')
            })
    }
    const handleForgotPasswordVerification = (formValues: any) => {
        const fPVericationData = {
            email: forgotPasswordValues.email,
            otp: formValues.otp,
            newPassword: formValues.password
        };
        setIsForgotPasswordVerification(true)
        AuthService.verifyResetPasswordOtp(fPVericationData)
            .then((res) => {
                setIsForgotPasswordVerification(false)
                setForgotPasswordVerificationValues(initiaFPWValues);
                setForgotPasswordValues(initiaForgotPasswordValues);
                setIsAuthForm('login')
                notifySucess("Successfully reset your password");
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsForgotPasswordVerification(false)
                }, timeout);
                notifyError('Invalid OTP. Please try again.')
            })

    }
    const handleOtp = (otpValue: string | { otp: string }) => {
        const verifyOtpData = {
            otp: (otpValue as { otp: string }).otp,
            email: signUpValues.email
        }
        setIsOtpVerificationBtn(true)
        AuthService.verifyOtp(verifyOtpData)
            .then((res) => {
                setIsOtpVerificationBtn(false)
                navigate('/dashboard')
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsOtpVerificationBtn(false)
                }, timeout);
                notifyError('Invalid OTP. Please try again.')
            })
    }

    return <div className={classes.bgContainer}>
        <div className={classes.navContainer} style={{ borderBottom: isMobile ? "1px solid white" : "none" }}>
            {
                isMobile ? <MobileHeader /> : <HeaderNavBar toasterId={toasterId} icon={flyghtcloud_logo} color='#FFFFFF' btnStatus={isLoginDisabled} />
            }
        </div>
        <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", }}>
            <div style={{ width: "600px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {
                    isAuthForm === 'login' ?
                        <>
                            <Login
                                onChangeAuthForm={(formType: string) => setIsAuthForm(formType)}
                                buttonState={isLoginDisabled}
                            />
                            <Toaster toasterId={toasterId} position="top-end" />
                        </>
                        :
                        isAuthForm === 'SignUp' ?
                            <>
                                <Signup onSubmit={(d: any) => {
                                    handleSignUp(d);
                                }}
                                    initialState={initialSignUpValues}
                                    onChangeAuthForm={handleGoBack}
                                    formData={signUpValues}
                                    setSignUpValues={setSignUpValues}
                                    buttonState={isSignUpDisabled}
                                />
                                <Toaster toasterId={toasterId} position="top-end" />
                            </>
                            :
                            isAuthForm === 'otpVerification' ?
                                <>
                                    <OTPVerfication
                                        onSubmit={(d: any) => {
                                            handleOtp(d);
                                        }}
                                        onChangeAuthForm={(formType: string) => setIsAuthForm(formType)}
                                        handleResend={(d: any) => {
                                            handleSignUp(d);
                                        }}
                                        signUpFormData={signUpValues}
                                        buttonState={isOtpVerificationBtn}

                                    />
                                    <Toaster toasterId={toasterId} position="top-end" />
                                </>
                                :
                                isAuthForm === 'forgotPassword' ?
                                    <>
                                        <ForgotPassword
                                            onSubmit={(d: any) => {
                                                handleForgotPassword(d);
                                            }}
                                            initialState={initiaForgotPasswordValues}
                                            onChangeAuthForm={handleGoBack}
                                            formData={forgotPasswordValues}
                                            setForgotPasswordValues={setForgotPasswordValues}
                                            buttonState={isForgotDisabaled}

                                        />
                                        <Toaster toasterId={toasterId} position="top-end" />
                                    </>
                                    :
                                    isAuthForm === 'forgotPasswordVerification' ?
                                        <>
                                            <ForgotPasswordVerification
                                                onSubmit={(d: any) => {
                                                    handleForgotPasswordVerification(d);
                                                }}
                                                initialState={initiaFPWValues}
                                                onChangeAuthForm={handleGoBack}
                                                formData={forgotPasswordVerificationValues}
                                                setForgotPasswordVerificationValues={setForgotPasswordVerificationValues}
                                                buttonState={isForgotPasswordVerification}
                                            />
                                            <Toaster toasterId={toasterId} position="top-end" />
                                        </>

                                        :
                                        <ApprovalPending />
                }
            </div>
        </div>
    </div>

}


const useStyles = makeStyles({
    navContainer: {
        width: '100%',
        height: "3rem",
        minHeight: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    navLinkContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        ...shorthands.gap("0.5rem"),
        ...shorthands.textDecoration("none"),
        fontSize: "1rem",
        marginRight: "1.5rem",
        textAlign: "center",
        ':hover': {
            ...shorthands.borderBottom("1px", "solid", "#E5E8EB"),
        },
    },
    activeNavLink: {
        ...shorthands.borderBottom("2px", "solid", "#E5E8EB"),
        fontWeight: "bold"
    },
    isActives: {
        ...shorthands.borderBottom("2px", "solid", "#242B35"),
        fontWeight: "blue"

    },


    branding: {
        display: "flex",
        ...shorthands.gap('0.375rem'),
        color: tokens.colorNeutralBackground1,
        fontSize: "21px",
        lineHeight: "18px",
        height: "100%",
        alignItems: "center",
        cursor: "pointer"
    },
    bgContainer: {
        backgroundImage: `url(${home_bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        color: "#ffff",
        width: "100vw",
        height: "100vh",
        margin: 0,
        padding: 0,
        display: "flex",
        flexDirection: "column"
    },
})
