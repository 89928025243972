import { Avatar, Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Toast, ToastTitle, Toaster, makeStyles, mergeClasses, shorthands, useToastController } from '@fluentui/react-components';
import {
    Drawer, DrawerBody,
    DrawerHeader,
} from '@fluentui/react-drawer';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';
import ToastErrorIcon from "../../../assets/icons/toast_error.svg";
import { API_BASE } from '../../../constants';
import { AuthService } from '../../../services/openapi';
import { ApprovalPending } from '../../auth/ApprovalPending';
import { ForgotPassword } from '../../auth/ForgotPassword';
import { ForgotPasswordVerification } from '../../auth/ForgotPasswordVerification';
import { Login } from '../../auth/Login';
import { OTPVerfication } from '../../auth/OTPVerfication';
import { Signup } from '../../auth/Signup';
import BookDemoModal from './BookDemoModal';

const navItems = [
    {
        href: "/",
        name: "Home",
    },
    {
        href: "/solutions",
        name: "Solutions",
    },

    {
        href: "/faqs",
        name: "FAQs",
    },

    {
        href: "/about-us",
        name: "About Us",
    },
    {
        href: "/whats-new",
        name: "What's New?",
    },

]

const profileItems = [
    {
        href: "/projects",
        name: "Projects",
    },
    {
        href: "/workFlow-library",
        name: "Workflows Library",
    },
    {
        href: "/discover-apps",
        name: "Discover Apps",
    },
    {
        href: "/",
        name: "Sign Out",
    },
]
interface headerNavProps {
    icon: string,
    color: string,
    btnBg?: string,
    login?: (d: any) => void,
    toasterId?: string,
    signInBorder?: boolean,
    btnStatus: boolean
}
interface SignUpProps {
    fullName: string,
    email: string,
    password: string,
    confirmPassword: string
}
interface ForgotPAsswordProps {
    email: string,
}

interface ForgotPasswordVerificationProps {
    otp: string,
    password: string,
    confirmPassword: string
}
function HeaderNavBar({ icon, color, btnBg, toasterId, signInBorder, btnStatus }: headerNavProps) {
    const { me, setMe } = React.useContext(AuthContext);
    const classes = useStyles();
    const timeout = 2000;
    const { dispatchToast } = useToastController(toasterId);
    const initialSignUpValues = { fullName: '', email: '', password: '', confirmPassword: '' };
    const initiaForgotPasswordValues = { email: '', };
    const initiaFPWValues = { otp: '', password: '', confirmPassword: '' };
    const [bookDemoModalVisible, setBookDemoModalVisible] = React.useState(false)
    const [isOpen, setIsOpen] = React.useState(false);
    const [isAuthForm, setIsAuthForm] = React.useState<'login' | string>('login');
    const [signUpValues, setSignUpValues] = React.useState<SignUpProps>(initialSignUpValues);
    const [forgotPasswordValues, setForgotPasswordValues] = React.useState<ForgotPAsswordProps>(initiaForgotPasswordValues);
    const [forgotPasswordVerificationValues, setForgotPasswordVerificationValues] = React.useState<ForgotPasswordVerificationProps>(initiaFPWValues);
    const [isSignUpDisabled, setIsSignUpDisabled] = React.useState(false);
    const [isOtpVerificationBtn, setIsOtpVerificationBtn] = React.useState(false);
    const [isForgotDisabaled, setIsForgotDisabaled] = React.useState(false);
    const [isForgotPasswordVerification, setIsForgotPasswordVerification] = React.useState(false);
    const navigate = useNavigate();

    const notifyError = (err: string) => dispatchToast(
        <Toast
            style={{ background: "#FDE7E9" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{err}</ToastTitle>
        </Toast>,
        { timeout, intent: "error" }
    );

    const notifySucess = (msg: string) => dispatchToast(
        <Toast
            style={{ background: "#DCF7EFEF" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{msg}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const logout = () => {
        setMe(null)
        AuthService.logout()
            .catch((err) => console.error(err))
    };

    const toggleBookDemoModal = (flag: boolean) => {
        setBookDemoModalVisible(flag)
    }
    const handleGoBack = (formType: string) => {
        setIsAuthForm(formType)
    }
    type SignUpRequest = {
        email: string;
        fullName: string;
        password: string;
        confirmPassword: string;
    };
    const handleSignUp = ({ fullName, email, password, }: SignUpRequest) => {
        setIsSignUpDisabled(true)
        const signUpData = {
            name: fullName,
            email,
            password,
        };
        AuthService.signUp(signUpData)
            .then((res) => {
                setIsSignUpDisabled(false)
                setIsAuthForm('otpVerification')
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsSignUpDisabled(false)
                }, timeout);
                notifyError('This email is already in use. Please log in or try another')
            })
    }
    const handleForgotPassword = (formValues: any) => {
        setIsForgotDisabaled(true)
        AuthService.resetPassword(formValues)
            .then((res) => {
                setIsForgotDisabaled(false)
                setIsAuthForm('forgotPasswordVerification')
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsForgotDisabaled(false)
                }, timeout);
                notifyError('The entered email does not exist.')
            })
    }
    const handleForgotPasswordVerification = (formValues: any) => {
        const fPVericationData = {
            email: forgotPasswordValues.email,
            otp: formValues.otp,
            newPassword: formValues.password
        };
        setIsForgotPasswordVerification(true)
        AuthService.verifyResetPasswordOtp(fPVericationData)
            .then((res) => {
                setIsForgotPasswordVerification(false)
                setForgotPasswordVerificationValues(initiaFPWValues);
                setForgotPasswordValues(initiaForgotPasswordValues);
                setIsAuthForm('login')
                notifySucess("Successfully reset your password");
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsForgotPasswordVerification(false)
                }, timeout);
                notifyError('Invalid OTP. Please try again.')
            })

    }
    const handleOtp = (otpValue: string | { otp: string }) => {
        const verifyOtpData = {
            otp: (otpValue as { otp: string }).otp,
            email: signUpValues.email
        }
        setIsOtpVerificationBtn(true)
        AuthService.verifyOtp(verifyOtpData)
            .then((res) => {
                setIsOtpVerificationBtn(false)
                navigate('/dashboard')
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsOtpVerificationBtn(false)
                }, timeout);
                notifyError('Invalid OTP. Please try again.')
            })
    }

    const handleSignIn = () => {
        window.location.href = `${API_BASE}/api/sso/login?client_id=FLYGHTCLOUD`
    }

    return <div className={classes.navContainer} >
        <div className={classes.branding}>
            <img src={icon} alt="FLYGHT CLOUD Logo" />
            <p style={{ color: color, fontWeight: "400" }}>FLYGHT<span style={{ fontWeight: "700" }}>CLOUD</span></p>
        </div>
        <div style={{ display: "flex", position: "relative", flexWrap: "wrap", alignItems: "center" }}>
            {
                navItems.map(item =>
                    <NavLink
                        key={item.href}
                        style={{ color: color }}
                        to={item.href}
                        className={({ isActive }) =>
                            mergeClasses(
                                classes.navLinkContainer,
                                isActive && (item.href === '/' ? classes.activeNavLink : classes.isActives)
                            )
                        }
                    >
                        {item.name}
                    </NavLink>
                )
            }
            <div>
                <Button style={{ background: "#0A84FF", marginRight: "1.5rem", padding: "8px 15px", border: "none", color: "#FAFAFA" }} shape="circular" onClick={() => { toggleBookDemoModal(true) }}>Connect to Expert</Button>
                {
                    !me ?
                        <>
                            <Drawer position='end'
                                size='medium'
                                modalType="non-modal"
                                open={isOpen}
                                onOpenChange={(_, { open }) => setIsOpen(open)}
                                style={{
                                    background: 'rgb(224 224 224 / 60%)',
                                    backdropFilter: 'blur(5px)',
                                    width: "34.5vw"
                                }}
                            >
                                <DrawerHeader>
                                    <Button
                                        appearance="subtle"
                                        aria-label="Close"
                                        icon={<Dismiss24Regular />}
                                        onClick={() => {
                                            setIsOpen(false)
                                            setIsAuthForm("login")
                                            setSignUpValues(initialSignUpValues);
                                            setForgotPasswordValues(initiaForgotPasswordValues)
                                            setForgotPasswordVerificationValues(initiaFPWValues)
                                        }}
                                    />
                                </DrawerHeader>

                                <DrawerBody style={{ display: "flex", background: "none", justifyContent: "center", alignItems: "center" }}>
                                    {
                                        isAuthForm === 'login' ?
                                            <>
                                                <Login
                                                    onChangeAuthForm={(formType: string) => setIsAuthForm(formType)}
                                                    buttonState={btnStatus}
                                                />
                                                <Toaster toasterId={toasterId} position="top-end" />
                                            </>
                                            :
                                            isAuthForm === 'SignUp' ?
                                                <>
                                                    <Signup onSubmit={(d: any) => {
                                                        handleSignUp(d);
                                                    }}
                                                        initialState={initialSignUpValues}
                                                        onChangeAuthForm={handleGoBack}
                                                        formData={signUpValues}
                                                        setSignUpValues={setSignUpValues}
                                                        buttonState={isSignUpDisabled}
                                                    />
                                                    <Toaster toasterId={toasterId} position="top-end" />
                                                </>
                                                :
                                                isAuthForm === 'otpVerification' ?
                                                    <>
                                                        <OTPVerfication
                                                            onSubmit={(d: any) => {
                                                                handleOtp(d);
                                                            }}
                                                            onChangeAuthForm={(formType: string) => setIsAuthForm(formType)}
                                                            handleResend={(d: any) => {
                                                                handleSignUp(d);
                                                            }}
                                                            signUpFormData={signUpValues}
                                                            buttonState={isOtpVerificationBtn}

                                                        />
                                                        <Toaster toasterId={toasterId} position="top-end" />
                                                    </>
                                                    :
                                                    isAuthForm === 'forgotPassword' ?
                                                        <>
                                                            <ForgotPassword
                                                                onSubmit={(d: any) => {
                                                                    handleForgotPassword(d);
                                                                }}
                                                                initialState={initiaForgotPasswordValues}
                                                                onChangeAuthForm={handleGoBack}
                                                                formData={forgotPasswordValues}
                                                                setForgotPasswordValues={setForgotPasswordValues}
                                                                buttonState={isForgotDisabaled}

                                                            />
                                                            <Toaster toasterId={toasterId} position="top-end" />
                                                        </>
                                                        :
                                                        isAuthForm === 'forgotPasswordVerification' ?
                                                            <>
                                                                <ForgotPasswordVerification
                                                                    onSubmit={(d: any) => {
                                                                        handleForgotPasswordVerification(d);
                                                                    }}
                                                                    initialState={initiaFPWValues}
                                                                    onChangeAuthForm={handleGoBack}
                                                                    formData={forgotPasswordVerificationValues}
                                                                    setForgotPasswordVerificationValues={setForgotPasswordVerificationValues}
                                                                    buttonState={isForgotPasswordVerification}
                                                                />
                                                                <Toaster toasterId={toasterId} position="top-end" />
                                                            </>

                                                            :
                                                            <ApprovalPending />
                                    }

                                </DrawerBody>
                            </Drawer>

                            <Button
                                style={{ padding: "8px 15px", border: signInBorder ? "1px solid #242B35" : "none", color: signInBorder ? "#242B35" : "#617AFA", }} shape="circular"
                                onClick={handleSignIn}
                            >
                                Sign In
                            </Button>
                        </>
                        : <Button
                            style={{ padding: "8px 15px", border: "none", color: "#586A84", background: btnBg ? btnBg : "" }} shape="circular"
                        >
                            <Link to='/dashboard'>Dashboard</Link>
                        </Button>
                }
                {
                    me ? <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <Avatar
                                name={me?.email}
                                style={{ cursor: "pointer", marginLeft: "1rem" }}
                            />
                        </MenuTrigger>
                        <MenuPopover className={classes.layersPopover}>
                            <MenuList style={{ width: "150px" }}>
                                {profileItems.map((item, index) => <MenuItem key={index} style={{ padding: 0 }}>
                                    <Link className={classes.menulist} to={item.href} onClick={item.href === '/' ? logout : undefined}>
                                        <p>
                                            {item.name}
                                        </p>
                                    </Link>
                                </MenuItem>)}
                            </MenuList>
                        </MenuPopover>
                    </Menu> : null
                }
            </div>
        </div>
        <BookDemoModal visible={bookDemoModalVisible} toggleModal={toggleBookDemoModal} />

    </div >
}


const useStyles = makeStyles({
    navContainer: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: ".5rem",
        paddingBottom: ".5rem",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...shorthands.gap("0.5rem"),
        ...shorthands.borderBottom("1px", "solid", "#E5E8EB"),
    },
    navLinkContainer: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        ...shorthands.gap("0.5rem"),
        ...shorthands.textDecoration("none"),
        fontSize: "1rem",
        marginRight: "1.5rem",
        textAlign: "center",
        ':hover': {
            ...shorthands.borderBottom("1px", "solid", "#E5E8EB"),
        },
    },
    activeNavLink: {
        ...shorthands.borderBottom("2px", "solid", "#E5E8EB"),
        fontWeight: "bold"
    },
    isActives: {
        ...shorthands.borderBottom("2px", "solid", "#242B35"),
        fontWeight: "blue"

    },
    expButton: {
        ...shorthands.padding("6px 12px"),
        ":hover": {
            backgroundColor: "#E5E5FE",
        }
    },
    menulist: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        ...shorthands.gap('0.5rem'),
        "> p": {
            color: "black",
            fontSize: "14px"
        },
        ...shorthands.textDecoration("none"),
    },
    layersPopover: {
        top: "4px !important",
    },
    branding: {
        display: "flex",
        ...shorthands.gap('.5rem'),
        fontSize: "21px",
        fontWeight: "700",
        lineHeight: "18px",
        height: "100%",
        alignItems: "center",
    },
})

export default HeaderNavBar